.w-16{
    width:  16px;
    height: 16px;
  }
  .w-20{
    width:  20px;
    height: 20px;
  }
  .w-24{
    width:  24px;
    height: 24px;
  }
  .w-32{
    width:  32px;
    height: 32px;
  }
  .w-40{
    width:  40px;
    height: 40px;
  }
  .w-48{
    width:  48px;
    height:  48px;
  }
  .w-56{
    width:  56px;
    height:  56px;
  }
  .w-64{
    width:  64px;
    height:  64px;
  }
  
  .w-xxl{
    width: 60px;
  }
  .w-xs{
    width: 90px;
  }
  .w-sm{
    width: 120px;
  }
  .w{
    width: 180px;
  }
  .w-md{
    width: 200px;
  }
  .w-lg{
    width: 240px;
  }
  .w-xl{
    width: 280px;
  }
  .w-xxl{
    width: 320px;
  }
  
  .w-full{
    width: 100%;
  }
  .w-auto{
    width: auto;
  }
  .h-auto{
    height: auto;
  }
  .h-full{
    height: 100%;
  }
  
  @media (max-width: 991px) {
    .w-auto-sm{
      width: auto;
    }
    .w-full-sm{
      width: 100%;
    }
  }
  
  @media (max-width: 767px) {
    .w-auto-xs{
      width: auto;
    }
    .w-full-xs{
      width: 100%;
    }
  }
  